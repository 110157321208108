<template>
  <RecoverPassword />
</template>

<script>
import RecoverPassword from "@/components/RecoverPassword/RecoverPassword";
export default {
  name: "RecoverPasswordContainer",
  components: {
    RecoverPassword,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module src="./style.css"></style>
