<template>
  <RecoverPassword />
</template>
<script>
import RecoverPassword from "@/containers/RecoverPassword/RecoverPassword.vue";
import { defineComponent } from "vue";
export default defineComponent({
  setup() {},
  components: {
    RecoverPassword,
  },
});
</script>
